import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ShouyeView from "../views/ShouyeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ShouyeView",
    component: ShouyeView,
  },
  {
    path: "/MainView",
    name: "MainView",
    component: () => import("../views/MainView.vue"),
    children: [
      {
        path: "/LawView",
        alias: "",
        name: "LawView",
        component: () => import("../views/LawView.vue"),
      },
      {
        path: "/CaseView",
        name: "CaseView",
        component: () => import("../views/CaseView.vue"),
      },
      {
        path: "/InstrumentsView",
        name: "InstrumentsView",
        component: () => import("../views/InstrumentsView.vue"),
      },
      {
        path: "/ContractView",
        name: "ContractView",
        component: () => import("../views/ContractView.vue"),
      },
      {
        path: "/CourtView",
        name: "CourtView",
        component: () => import("../views/CourtView.vue"),
      },
      {
        path: "/ExamView",
        name: "ExamView",
        component: () => import("../views/ExamView.vue"),
      },
    ],
  },
  {
    path: "/MedicalView",
    name: "MedicalView",
    component: () => import("../views/MedicalView.vue"),
    children: [
      {
        path: "/MedicView",
        alias: "",
        name: "MedicView",
        component: () => import("../views/MedicView.vue"),
      },
    ],
  },
  {
    path: "/EducationView",
    name: "EducationView",
    component: () => import("../views/EducationView.vue"),
    children: [
      {
        path: "/EducaView",
        alias: "",
        name: "EducaView",
        component: () => import("../views/EducaView.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/ServiceView.vue"),
  },
  {
    path: "/exemption",
    name: "exemption",
    component: () => import("../views/ExemptionView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
